const ethersJsErrors = [
  'NOT_IMPLEMENTED',
  'SERVER_ERROR',
  'TIMEOUT',
  'UNKNOWN_ERROR',
  'BUFFER_OVERRUN',
  'INVALID_ARGUMENT',
  'MISSING_ARGUMENT',
  'MISSING_NEW',
  'UNEXPECTED_ARGUMENT',
  'CALL_EXCEPTION',
  'NONCE_EXPIRED',
  'REPLACEMENT_UNDERPRICED',
  'TRANSACTION_REPLACED',
  'NETWORK_ERROR',
  'INSUFFICIENT_FUNDS',
  'UNPREDICTABLE_GAS_LIMIT',
  'CALL_EXCEPTION',
  'NUMERIC_FAULT',
]

export default ethersJsErrors
