import { createContext } from 'react'

export interface IModalContext {
  isOpen: boolean
  setModalOpen: (isOpen: boolean) => void
}

const initialContext = {
  isOpen: false,
  setModalOpen: () => false,
}
// 지금은 Hamburger Menu를 위해 사용하지만,
// 추후에 다른 모달을 위해 사용할 수 있음
// 그 경우 isOpen: [key: string]: boolean , key: 'hamburgerMenu' | 'otherModal' 등으로 확장

const ModalContext = createContext<IModalContext>(initialContext)

export default ModalContext
