import { createContext } from 'react'
import MainnetType from 'src/enums/MainnetType'

export interface IMainnetContextTypes {
  type: MainnetType
  unsupportedChainid: boolean
  active: boolean
}

export interface IMainnetContext extends IMainnetContextTypes {
  changeMainnet: (mainnetChainId: number) => Promise<void>
  setCurrentMainnet: (data: MainnetType) => void
}

export const initialMainnetData: IMainnetContextTypes = {
  type: MainnetType.Ethereum,
  unsupportedChainid: false,
  active: false,
}

export const initialMainnetContext = {
  ...initialMainnetData,
  changeMainnet: async (): Promise<void> => {},
  setCurrentMainnet: (): void => {},
}

const MainnetContext = createContext<IMainnetContext>(initialMainnetContext)

export default MainnetContext
