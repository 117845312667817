import { providers } from 'ethers'
export type Web3ProviderType = providers.Web3Provider

export default function getLibrary(
  provider: providers.ExternalProvider | providers.JsonRpcFetchFunc,
): Web3ProviderType {
  const library = new providers.Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}
