import './i18n'

import { Web3ReactProvider } from '@web3-react/core'
import { BrowserRouter as Router } from 'react-router-dom'

import AppNavigator from './AppNavigator'
import getLibrary from './core/utils/getLibrary'
import 'src/stylesheet/public.scss'
import 'src/stylesheet/pc.scss'
import 'src/stylesheet/tablet.scss'
import 'src/stylesheet/mobile.scss'
import LanguageProvider from './providers/LanguageProvider'
import MainnetProvider from './providers/MainnetProvider'
import ModalProvider from './providers/ModalProvider'
import TxProvider from './providers/TxProvider'
import UnstoppableDomainsProvider from './providers/UnstoppableDomainsProvider'

const App: React.FC = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <MainnetProvider>
        <TxProvider>
          <UnstoppableDomainsProvider>
            <ModalProvider>
              <Router>
                <LanguageProvider>
                  <AppNavigator />
                </LanguageProvider>
              </Router>
            </ModalProvider>
          </UnstoppableDomainsProvider>
        </TxProvider>
      </MainnetProvider>
    </Web3ReactProvider>
  )
}

export default App
