import { UAuthConnector } from '@uauth/web3-react'
import injectedConnector from 'src/core/connectors/injectedConnector'
import { isProduction, isStaging } from '../envs'
import walletConnectConnectorFactory from './walletConnectConnectorFactory'

// Instanciate your other connectors.

const walletConnectConnector = walletConnectConnectorFactory()

const getRedirectUri = (lngCode: 'ko' | 'en'): string => {
  const base = (function () {
    if (isProduction) {
      return 'https://www.elyfi.world/'
    }
    if (isStaging) {
      return 'https://staging.elyfi.world/'
    }
    return 'http://localhost:3000/'
  })()
  return base + lngCode
}

const CLIENT_ID = process.env.REACT_APP_UD_CLIENT_ID
const uAuthConnector = (lng?: string): UAuthConnector => {
  const lngCode = lng === 'en' ? 'en' : 'ko'
  const redirectUri = getRedirectUri(lngCode)
  return new UAuthConnector({
    clientID: CLIENT_ID,
    redirectUri,
    // postLogoutRedirectUri을 사용하는 경우 에러 발생
    // postLogoutRedirectUri: redirectUri,

    // Scope must include openid and wallet
    scope: 'openid wallet',

    // Injected and walletconnect connectors are required.
    connectors: {
      injected: injectedConnector,
      walletconnect: walletConnectConnector,
    },
  })
}

export default uAuthConnector
