import { UserInfo } from '@uauth/js'
import { useState } from 'react'
import UnstoppableDomainsContext from '../contexts/UnstoppableDomainsContext'
const UnstoppableDomainsProvider: React.FC = (props) => {
  const [name, setName] = useState<string>('')

  const formatFullDomain = (fullDomain: string) => {
    if (!fullDomain) return ''
    // ex) elysia.wallet
    // domainName: 'elysia'
    // topLevelDomain: 'wallet'
    const [domainName, topLevelDomain] = fullDomain.split('.')
    const result = topLevelDomain
      ? `${domainName.slice(0, 10)}...${topLevelDomain}`
      : ''
    return result
  }

  const setUDName = (userInfo: UserInfo) => {
    const sub = userInfo?.sub
    const name = formatFullDomain(sub)
    setName(name)
  }

  return (
    <UnstoppableDomainsContext.Provider
      value={{
        UDName: name,
        setUDName,
      }}>
      {props.children}
    </UnstoppableDomainsContext.Provider>
  )
}

export default UnstoppableDomainsProvider
