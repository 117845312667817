import prodVars from './prod.json'
import testVars from './test.json'

interface EnvironmentVariables {
  moneyPool: {
    bscMoneyPoolAddress: string
    moneyPoolAddress: string
    daiTokenizerAddress: string
    usdtTokenizerAddress: string
  }
  incentivePool: {
    prevDaiIncentivePool: string
    prevUSDTIncentivePool: string
    currentDaiIncentivePool: string
    currentUSDTIncentivePool: string
    busdIncentivePoolAddress: string
    usdcIncentivePoolAddress: string
  }
  market: {
    controllerAddress: string
    nftAddress: string
  }
  krPublicMarket: {
    contractAddress: string
  }
  krPrivateMarket: {
    contractAddress: string
  }
  staking: {
    elStakingPoolAddress: string
    elfyStakingPoolAddress: string
    elfyV2StakingPoolAddress: string
    elfyV2StakingPoolRewardAddress: string
    elfyBscStakingPoolAddress: string
  }
  lpStaking: {
    daiElfiPoolAddress: string
    ethElfiPoolAddress: string
    ethElfiV2PoolAddress: string
    daiElfiV2PoolAddress: string
    stakerAddress: string
    nonFungiblePositionAddress: string
    refundedAddress: string
  }
  token: {
    governanceAddress: string
    elAddress: string
    daiAddress: string
    usdtAddress: string
    usdcAddress: string
    wEthAddress: string
    bscElfiAddress: string
    busdAddress: string
    testBscElfiAddress: string
    testUsdcLTokenAddress: string
  }
  network: {
    requiredNetwork: string
    requiredChainId: number
    bscMainnetChainId: number
  }
  subgraphApiEndpoint: {
    subgraphURI: string
    lpTokenPoolSubgraphURL: string
    stakerSubgraphURL: string
    bscSubgraphURI: string
  }
  externalApiEndpoint: {
    etherscanURI: string
    bscscanURI: string
    cachedUniswapV3URL: string
    coingackoURL: string
  }
  tokenizer: {
    daiTokenizerAddress: string
    usdtTokenizerAddress: string
    busdTokenizerAddress: string
    usdcTokenizerAddress: string
  }
  jsonRpcUrl: {
    bsc: string
  }
  stakingV2MoneyPool: {
    elfiStaking: string
    elfiBscStaking: string
    elfiEthLp: string
    elfiDaiLp: string
  }
  dataPipeline: {
    eth: string
    bsc: string
  }
}

const ModeType = {
  PRODUCTION: ' production',
  STAGING: ' staging',
  DEV: ' dev',
  LOCAL: ' local',
} as const

export const mode = (() => {
  // 각 mode 가 상호 배타적이게 하기 위함.
  if (process.env.NODE_ENV === 'production') {
    return ModeType.PRODUCTION // 배포환경
  }
  if (process.env.REACT_APP_STAGING_MODE) {
    return ModeType.STAGING // 스테이징 환경
  }
  if (process.env.NODE_ENV === 'development') {
    return ModeType.LOCAL // 로컬 환경
  }
  return ModeType.DEV // 배포되었지만, 개발용 환경
})()

export const isLocal = mode === ModeType.LOCAL
export const isDevelopment = mode === ModeType.DEV || mode === ModeType.LOCAL
export const isStaging = mode === ModeType.STAGING
export const isProduction = mode === ModeType.PRODUCTION
console.log('!!!!!!!!!!!!!!!!temp!!!!!!!!!!!!!!!!!!')

// 특수한 목적으로 기존 환경을 고려하지 않고 테스트를 셋팅할 때 사용
export const isTest = process.env.REACT_APP_TEST_MODE

// 현재 모드를 보기 위한 개발자용 설정
if (window?.localStorage?.getItem('@showMode')) {
  console.log('currentMode: ', mode)
}

const vars =
  isProduction || isStaging
    ? (prodVars as unknown as EnvironmentVariables)
    : (testVars as unknown as EnvironmentVariables)

export default vars
