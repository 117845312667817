import { useState } from 'react'

import ModalContext from '../contexts/ModalContext'

const ModalProvider: React.FC = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const setModalOpen = (open: boolean) => {
    setIsOpen(open)
  }

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        setModalOpen,
      }}>
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
