import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import MainnetContext, {
  IMainnetContextTypes,
} from 'src/contexts/MainnetContext'
import { mainnets } from 'src/core/data/mainnets'
import { Web3ProviderType } from 'src/core/utils/getLibrary'
import MainnetType from 'src/enums/MainnetType'
import useCurrentChain from 'src/hooks/useCurrentChain'

// ! FIXME
// 2. Remove redundant loading(false)
const MainnetProvider: React.FC = (props) => {
  const { active, library } = useWeb3React<Web3ProviderType>()

  const currentChain = useCurrentChain()

  const [currentMainnet, setMainnet] = useState<IMainnetContextTypes>({
    type: window.sessionStorage.getItem('@network')
      ? window.sessionStorage.getItem('@network') === 'Ethereum'
        ? MainnetType.Ethereum
        : MainnetType.BSC
      : MainnetType.Ethereum,
    unsupportedChainid: true,
    active: false,
  })

  useEffect(() => {
    if (!active) {
      if (currentMainnet.unsupportedChainid) {
        setMainnet({
          ...currentMainnet,
          unsupportedChainid: false,
          active,
        })
      }
      return
    }
    if (currentChain !== undefined) {
      setMainnet({
        ...currentMainnet,
        type: currentChain.type,
        unsupportedChainid: false,
        active,
      })
      window.sessionStorage.setItem('@network', currentChain.type)
    } else {
      setMainnet({ ...currentMainnet, unsupportedChainid: true, active })
      console.log('Error!')
    }
  }, [currentChain, active])

  const setCurrentMainnet = (data: MainnetType) => {
    setMainnet({ ...currentMainnet, type: data })
  }

  const changeMainnet = async (mainnetChainId: number) => {
    if (!library) return
    const getChainData = mainnets.find((data) => {
      return data.chainId === mainnetChainId
    })
    try {
      await library.provider.request?.({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: getChainData!.chainHexId,
          },
        ],
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (getChainData) {
        setMainnet({
          ...currentMainnet,
          type: getChainData.type,
        })
      }
    } catch (e: any) {
      if (e.code === 4902) {
        try {
          getChainData
            ? await library.provider.request?.({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: getChainData.addParams.chainId,
                    chainName: getChainData.addParams.chainName,
                    nativeCurrency: getChainData.addParams.nativeCurrency,
                    blockExplorerUrls: getChainData.addParams.blockExplorerUrls,
                    rpcUrls: getChainData.addParams.rpcUrls,
                  },
                ],
              })
            : (console.log('undefined mainnet!!'),
              setMainnet({ ...currentMainnet, unsupportedChainid: true }))
        } catch (_e) {
          console.error(_e)
          setMainnet({ ...currentMainnet, unsupportedChainid: true })
        }
      }
      console.log(e)
      setMainnet({ ...currentMainnet, unsupportedChainid: true })
    }
  }

  return (
    <MainnetContext.Provider
      value={{
        ...currentMainnet,
        changeMainnet,
        setCurrentMainnet,
      }}>
      {props.children}
    </MainnetContext.Provider>
  )
}

export default MainnetProvider
