import { useWeb3React } from '@web3-react/core'
import { useEffect, lazy, Suspense, useContext } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import UnStoppableDomainsContext from 'src/contexts/UnstoppableDomainsContext'
import InjectedConnector from 'src/core/connectors/injectedConnector'
import MediaQuery from 'src/enums/MediaQuery'
import ScrollToTop from 'src/hooks/ScrollToTop'
import useMediaQueryType from 'src/hooks/useMediaQueryType'
import usePageTracking from 'src/hooks/usePageTracking'

const Deposit = lazy(() => import('src/components/Deposit'))
const Main = lazy(() => import('src/components/Main'))
const Governance = lazy(() => import('src/components/Governance'))
const StakingEL = lazy(() => import('src/components/Staking/ElStaking'))
const Staking = lazy(() => import('src/components/Staking'))
const LPStaking = lazy(() => import('src/components/LpStaking'))
const RewardPlan = lazy(() => import('src/components/RewardPlan'))
const LiquidityDetails = lazy(() => import('src/components/LiquidityDetails'))
const PortfolioDetail = lazy(() => import('src/components/Portfolio'))
const LegacyStaking = lazy(() => import('src/components/LegacyStaking'))
const LegacyStakingLP = lazy(
  () => import('src/components/LegacyStaking/LegacyLpstaking'),
)
const Faq = lazy(() => import('src/components/FAQ'))
const Market = lazy(() => import('src/components/Market'))
const KRNFTDetails = lazy(
  () => import('src/components/NFTDetails/KRNFTDetails'),
)
const USNFTDetails = lazy(
  () => import('src/components/NFTDetails/USNFTDetails'),
)
const MarketFaq = lazy(() => import('src/components/MarketFAQ'))

import 'src/stylesheet/public.scss'
import 'src/stylesheet/pc.scss'
import 'src/stylesheet/mobile.scss'

const Navigation = lazy(() => import('src/components/Navigation'))

import ModalContext from './contexts/ModalContext'
import {
  walletConnectConnectorFactory,
  uAuthConnector,
} from './core/connectors'
import { isWalletConnector, isUnstoppableDomains } from './utiles/connectWallet'

const walletConnectConnector = walletConnectConnectorFactory()

const AppNavigator: React.FC = () => {
  const { value: mediaQuery } = useMediaQueryType()
  const { setUDName } = useContext(UnStoppableDomainsContext)
  const { isOpen } = useContext(ModalContext)

  const { activate } = useWeb3React()
  const { lng } = useParams<{ lng: string }>()

  const fetchUnstoppableDomains = async () => {
    try {
      // 'uauth' getter 함수내에서 에러를 throw하는 케이스가 많아서
      // try catch로 핸들링 해야한다.
      const unstoppableDomainsUser = await uAuthConnector(lng)?.uauth?.user()
      setUDName(unstoppableDomainsUser)
    } catch (e) {
      console.log('e', e)
    }
  }

  usePageTracking()

  // 새로고침시 지갑연결 유지
  useEffect(() => {
    const isWalletInjected = localStorage.getItem('wallet') === 'injected'
    if (!isWalletInjected) {
      return
    }
    if (isUnstoppableDomains()) {
      activate(uAuthConnector(lng)).then(() => {
        if (isUnstoppableDomains()) {
          fetchUnstoppableDomains()
        }
      })
    } else {
      const connector = isWalletConnector()
        ? walletConnectConnector
        : InjectedConnector
      activate(connector)
    }
  }, [])

  const nullFallbackArea = (): JSX.Element => {
    return <div style={{ width: '100vw', height: '100vh' }} />
  }

  return (
    <div
      className={
        `elysia ${
          mediaQuery === MediaQuery.PC
            ? 'view-w'
            : mediaQuery === MediaQuery.Tablet
            ? 'view-t'
            : 'view-m'
        }`
        // "elysia"
      }
      style={{
        position: isOpen ? 'fixed' : 'initial',
      }}>
      <ScrollToTop />
      <Routes>
        <Route
          path=":lng"
          element={
            <Suspense fallback={nullFallbackArea()}>
              <Navigation />
            </Suspense>
          }>
          <Route
            index
            element={
              <Suspense fallback={nullFallbackArea()}>
                <Main />
              </Suspense>
            }
          />
          <Route path="market">
            <Route
              index
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <Market />
                </Suspense>
              }></Route>
            <Route
              path="guide"
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <MarketFaq />
                </Suspense>
              }
            />
            <Route
              path="bondnft/0"
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <USNFTDetails />
                </Suspense>
              }
            />
            <Route
              path="bondnft/1"
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <KRNFTDetails />
                </Suspense>
              }
            />
          </Route>

          <Route path="staking">
            <Route
              path="LP"
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <LPStaking />
                </Suspense>
              }
            />
            <Route
              path="EL"
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <StakingEL />
                </Suspense>
              }
            />
            <Route
              path="ELFI"
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <Staking />
                </Suspense>
              }
            />
          </Route>
          <Route path="deposit">
            <Route
              index
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <Deposit />
                </Suspense>
              }
            />
            <Route
              path=":id"
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <LiquidityDetails />
                </Suspense>
              }
            />
          </Route>

          <Route
            path="governance"
            element={
              <Suspense fallback={nullFallbackArea()}>
                <Governance />
              </Suspense>
            }
          />
          <Route path="portfolio">
            <Route
              path=":id"
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <PortfolioDetail />
                </Suspense>
              }
            />
          </Route>
          <Route path="rewardplan">
            <Route
              path=":stakingType"
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <RewardPlan />
                </Suspense>
              }
            />
          </Route>

          <Route path="legacystaking">
            <Route
              path="ELFI"
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <LegacyStaking />
                </Suspense>
              }
            />
            <Route
              path="LP"
              element={
                <Suspense fallback={nullFallbackArea()}>
                  <LegacyStakingLP />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="faq"
            element={
              <Suspense fallback={nullFallbackArea()}>
                <Faq />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </div>
  )
}

export default AppNavigator
