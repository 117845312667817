import { UserInfo } from '@uauth/js'
import { createContext } from 'react'

export interface IUnStoppableDomainsContext {
  UDName: string
  setUDName: (userInfo: UserInfo) => void
}

const initialContext = {
  UDName: '',
  setUDName: () => {},
}

const UnStoppableDomainsContext =
  createContext<IUnStoppableDomainsContext>(initialContext)

export default UnStoppableDomainsContext
