import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'

const usePageTracking = (): void => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  const gaId = process.env.REACT_APP_GA_ID

  useEffect(() => {
    if (!gaId) {
      return
    }
    ReactGA.initialize(gaId, {
      debug: process.env.NODE_ENV === 'development',
    })
    setInitialized(true)
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [initialized, location])
}

export default usePageTracking
